<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-button :variant="isSingle === true ? 'outline-warning' : 'warning'" type="button" @click="isSingle = true">View Single Promo</b-button> &nbsp;
          <b-button :variant="isSingle === true ? 'warning' : 'outline-warning'" type="button" @click="isSingle = false">View Multiple Promo</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link v-if="$can('generate_promotion') " :to="{name: 'Add Promotion'}" class="btn btn-primary"><i class="fa fa-plus"></i> Generate</router-link>&nbsp;
          <b-button variant="success" type="button" @click="exportTable">Export</b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="isSingle === true">
      <single />
    </div>
    <div v-else>
      <multiple />
    </div>
  </div>
</template>

<script>
  import Single from './Single.vue'
  import Multiple from './Multiple.vue'
  export default {
    components: {
      Single,
      Multiple
    },
    data() {
      return {
        isSingle: true,
        startDate: '',
        endDate: '',
        isLoading: false,
        filterText: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
      }
    },
    methods: {
      exportTable() {
        let startDate = ''
        let endDate = ''
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`data-promotion/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      }
    }
  }
</script>
<style>

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
